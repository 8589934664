import React from "react"
import styled from "styled-components"
import { IoArrowForward } from "@react-icons/all-files/io5/IoArrowForward"

const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.colors.white};
  position: fixed;
  top: 0;
  right: 0;
  z-index: 100;

  @media (min-width: 1000px) {
    width: 50vw;
  }
`

const Description = styled.div`
  max-width: 300px;
`

const H1 = styled.h1`
  font-size: ${({ theme }) => theme.font.size.m};
  font-weight: 700;
  padding-bottom: 30px;
`

const Text = styled.p`
  font-size: ${({ theme }) => theme.font.size.r};
  font-weight: 300;
  padding-bottom: 20px;
`

const Links = styled.a`
  font-weight: 700;
  color: inherit;
  text-decoration: none;
  padding-right: 20px;
`

const Menu = styled.div`
  position: absolute;
  right: 0;
`

const Dot = styled.div`
  margin: 5px 30px;
  width: 10px;
  height: 10px;
  border-radius: 10px;
  background: #534e4e;
  opacity: 50%;

  &.selected {
    opacity: 100%;
  }
`

const Close = styled.button`
  border: none;
  outline: none;
  background: none;
  position: absolute;
  top: 0;
  right: 0;
  margin: 22px;
  padding: 0;
  width: 25px;
  height: 25px;

  &:after,
  :before {
    content: "";
    position: absolute;
    top: 12;
    left: 0;
    background: #000;
    width: 25px;
    height: 1px;
  }

  &:after {
    transform: rotate(45deg);
  }

  &:before {
    transform: rotate(-45deg);
  }

  &:hover {
    opacity: 50%;
  }
`

const StyledArrow = styled(IoArrowForward)`
  transform: rotate(-45deg);
`

export default function WorkModal({ item, close }) {
  const dots = []
  for (let i = 0; i <= item.images.length; i++) {
    if (i === 0) {
      dots.push(<Dot key={i} className="selected" />)
    } else {
      dots.push(<Dot key={i} />)
    }
  }

  return (
    <Wrapper data-item="modal">
      <Close onClick={close} />
      <Description>
        <H1>{item.title}</H1>
        <Text>{item.description}</Text>
        {item.links.preview.length > 1 && (
          <Links href={item.links.preview} target="_blank">
            Live preview <StyledArrow />
          </Links>
        )}
        {item.links.code.length > 1 && (
          <Links href={item.links.code} target="_blank">
            Code <StyledArrow />
          </Links>
        )}
      </Description>
      <Menu>{dots.length > 1 && dots}</Menu>
    </Wrapper>
  )
}
