import React from "react"
import { Element } from "react-scroll"
import styled from "styled-components"

import MainTemplate from "templates/MainTemplate"
import Hero from "components/Hero/Hero"
import Contact from "components/Contact/Contact"
import Work from "components/Work/Work"
import AboutMe from "../components/AboutMe/AboutMe"
import Header from "../components/Header/Header"
import Nav from "../components/Nav/Nav"

const MobileNav = styled.div`
  position: fixed;
  bottom: 0;
  width: 100vw;
  background-color: ${({ theme }) => theme.colors.white};
  background: linear-gradient(
    0deg,
    ${({ theme }) => theme.colors.white} 80%,
    rgba(0, 0, 0, 0) 100%
  );
  padding: 2rem 2rem 1rem;

  @media all and (min-width: 768px) {
    display: none;
  }
`

const Main = () => (
  <MainTemplate>
    <Header />
    <MobileNav>
      <Nav />
    </MobileNav>
    <Element name="home">
      <Hero />
    </Element>
    <Element name="aboutMe">
      <AboutMe />
    </Element>
    <Element name="portfolio">
      <Work />
    </Element>
    <Element name="contact">
      <Contact />
    </Element>
  </MainTemplate>
)

export default Main
