import React from "react"
import styled from "styled-components"
import Logo from "../Logo"
import Nav from "../Nav/Nav"

const HeaderWrapper = styled.header`
  position: fixed;
  width: 100vw;
  top: -5px;
  padding: calc(2rem + 5px) 2rem 2rem;
  backdrop-filter: blur(5px);
  mask: linear-gradient(black 90%, transparent);
  background: linear-gradient(
    0deg,
    ${({ theme }) => theme.colors.white}61 72%,
    ${({ theme }) => theme.colors.white} 92%
  );
`
const HeaderContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 600;
  > nav {
    display: none;
  }

  @media all and (min-width: 768px) {
    margin: 0 5%;
    > nav {
      display: block;
    }
  }
`

const Title = styled.div`
  display: flex;
  align-items: center;
`

const H2 = styled.h2`
  font-size: ${({ theme }) => theme.font.size.r};
  margin-left: 15px;
`

const Header = () => (
  <HeaderWrapper>
    <HeaderContent className="container">
      <Title>
        <Logo width="30px" />
        <H2>Adrian Krasnodębski</H2>
      </Title>
      <Nav />
    </HeaderContent>
  </HeaderWrapper>
)

export default Header
