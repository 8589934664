import React from "react"
import styled from "styled-components"

const Pill = styled.div`
  margin: 0 7px 12px 0;
  padding: 10px 15px;
  border-radius: 30px;
  border: 1px solid ${({ theme }) => theme.colors.dark};
`

const SkillItem = ({ name }) => (
  <>
    <Pill>{name}</Pill>
  </>
)

export default SkillItem
