import React from "react"
import styled from "styled-components"

const Copyright = styled.footer`
  width: 100%;
  margin-top: 2rem;
  padding-top: 2rem;
  font-size: ${({ theme }) => theme.font.size.s};
  font-weight: 300;
  border-top: 1px solid ${({ theme }) => theme.colors.white};
`

const Footer = () => (
  <Copyright>© {new Date().getFullYear()} Adrian Krasnodębski</Copyright>
)

export default Footer
