import React from "react"
import styled from "styled-components"

const SocialButton = styled.a`
  margin: 5px;
  padding: 7px 10px;
  border: 1px solid ${({ theme }) => theme.colors.white};
  border-radius: 20px;
  font-size: ${({ theme }) => theme.font.size.s};
  text-decoration: none;
  color: #fff;

  &:hover {
    background: #fff;
    color: #000;
  }
`

const SocialWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 3rem 0;
`

const socials = {
  linkedIn: {
    link: "https://www.linkedin.com/in/adrian-krasnodębski-80a34b195/",
    icon: "fab fa-linkedin",
  },
  messenger: {
    link: "https://m.me/nejwknfkejnfwoengwgkrghre",
    icon: "fab fa-facebook-messenger",
  },
  github: {
    link: "https://github.com/vansmoe",
    icon: "fab fa-github",
  },
}

const SocialBar = () => (
  <SocialWrapper>
    <SocialButton href={socials.linkedIn.link} target="_blank">
      Linked
      <span />
    </SocialButton>
    <SocialButton href={socials.messenger.link} target="_blank">
      <span /> Messenger
    </SocialButton>
    <SocialButton href={socials.github.link} target="_blank">
      <span /> GitHub
    </SocialButton>
  </SocialWrapper>
)

export default SocialBar
