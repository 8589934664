import React from "react"
import styled from "styled-components"

const Wrapper = styled.section`
  margin: 4rem auto;
`

const P = styled.p`
  max-width: 620px;
  margin: 2rem 5%;
  padding-bottom: 2rem;
  font-size: ${({ theme }) => theme.font.size.m};
  line-height: 1.5;
  font-weight: 300;

  &:first-of-type {
    font-weight: 500;
  }
`

function AboutMe() {
  return (
    <Wrapper className="container">
      <P>
        I&apos;ve 1.5+ years of commercial experience in building websites and
        web applications. I&apos;ve studied sociology at the University of
        Gdansk which give me ability to conduct qualitative and quantitative
        social research.
      </P>
      <P>
        My interest in web design started when I built my first computer 10
        years ago. Watching how products are designed inspires me to always keep
        the end user in mind. Any hobbies? Mainly geek stuff, like tech, puzzles
        and movies, but I also enjoy spending time offline during walking or
        reading books.
      </P>
    </Wrapper>
  )
}

export default AboutMe
