import React, { useEffect, useRef, useState } from "react"
import styled from "styled-components"
import { animateScroll as scroll } from "react-scroll"
import SocialBar from "components/Contact/SocialBar"
import Footer from "components/Footer/Footer"

const ContactArea = styled.div`
  height: 10vh;
  margin-top: 350px;
  transition: all 0.5s cubic-bezier(0.22, 1, 0.36, 1);
`

const StyledWrapper = styled.section`
  position: fixed;
  width: 100vw;
  bottom: -5px;
  transition: all 1.5s cubic-bezier(0.22, 1, 0.36, 1);
  padding: 4rem 3rem;
  border-radius: 20px 20px 0 0;
  background-color: ${({ theme }) => theme.colors.dark};
  color: ${({ theme }) => theme.colors.white};
`

const H = styled.h3`
  font-size: ${({ theme }) => theme.font.size.l};
  font-weight: 500;
  margin-bottom: 2rem;
`

const P = styled.p`
  padding: 1rem 0;
  font-size: ${({ theme }) => theme.font.size.m};
  font-weight: 400;
`

const Link = styled.a`
  color: inherit;
  text-decoration: none;
  transition: color 0.5s ease;

  &:hover {
    color: #fcd22a;
  }
`

const hiddenStyle = {
  bottom: "-100%",
  transform: "scale(0.8)",
}

const Contact = () => {
  const [cardStyle, setCardStyle] = useState(hiddenStyle)
  const contactArea = useRef(null)

  useEffect(() => {
    // eslint-disable-next-line no-undef
    const observer = new IntersectionObserver(
      entries => {
        const [{ isIntersecting }] = entries

        if (isIntersecting) {
          scroll.scrollToBottom({ smooth: true, duration: 500 })
          setCardStyle({})
        } else {
          setCardStyle(hiddenStyle)
        }
      }
      // { threshold: ".5" }
    )

    observer.observe(contactArea.current)

    return () => {
      observer.unobserve(contactArea.current)
    }
  }, [])

  return (
    <>
      <ContactArea ref={contactArea} />
      <StyledWrapper style={cardStyle}>
        <H>Let&apos;s stay in touch 👋</H>
        <P>
          <Link href="mailto:hello@adriankrasno.pl">hello@adriankrasno.pl</Link>
        </P>
        <P>
          <Link href="tel:+48691045545">+48 691 045 545</Link>
        </P>
        <SocialBar />
        <Footer />
      </StyledWrapper>
    </>
  )
}

export default Contact
