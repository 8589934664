import React, { useState } from "react"
import styled from "styled-components"
import { IoArrowForwardCircleSharp } from "@react-icons/all-files/io5/IoArrowForwardCircleSharp"
import WorkModal from "./WorkModal"

const Wrapper = styled.div`
  width: 100%;
  margin: 2rem 0;
  padding: 2rem 0;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: flex-end;
  background: none;
  outline: none;
  border: none;
  font: inherit;
  text-align: left;
  color: ${({ theme }) => theme.colors.dark};

  &:hover {
    opacity: 50%;
  }
`
const Text = styled.div`
  width: 70%;
`

const H = styled.h4`
  margin-bottom: 1rem;
  font-size: ${({ theme }) => theme.font.size.l};
  font-weight: 600;
`

const P = styled.p`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const WorkItem = ({ title, data }) => {
  const [preview, setPreview] = useState(false)

  const handleShow = () => {
    document.body.classList.add("no-overflow")
    setPreview(true)
  }
  const handleClose = () => {
    document.body.classList.remove("no-overflow")
    setPreview(false)
  }

  return (
    <>
      <Wrapper as="button" onClick={handleShow}>
        <Text>
          <H>{title}</H>
          <P>{data.description}</P>
        </Text>
        <IoArrowForwardCircleSharp size="4rem" />
      </Wrapper>
      {preview && <WorkModal item={data} close={handleClose} />}
    </>
  )
}

export default WorkItem
