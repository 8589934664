const data = [
  {
    title: "EcoCamper",
    description:
      "Simple static HTML website for small caravan rental business based in Warsaw, Poland. No frameworks, just simple vanilla JS and CSS.",
    year: 2020,
    links: {
      code: "https://github.com/vansmoe/EcoCamper",
      preview: "https://www.ecocamper.eu/",
    },
    images: [],
  },
  {
    title: "Szafawawa",
    description: "Website for furniture company in Wordpress.",
    year: 2019,
    links: {
      code: "",
      preview: "http://szafawawa.pl/",
    },
    images: [],
  },
  {
    title: "Color.ing",
    description:
      "Play with colors by typing them in the selected input. App made for fun, with Vue.js. Next features are planned in the future.",
    year: 2019,
    links: {
      code: "https://github.com/vansmoe/Color.ing",
      preview: "https://coloring.adriankrasno.pl",
    },
    images: [],
  },
  {
    title: "Splashit",
    description: "Unsplash copy 😁. Based on React and Hooks.",
    year: 2020,
    links: {
      code: "https://github.com/vansmoe/splashit",
      preview: "https://splashit.adriankrasno.pl",
    },
    images: [],
  },
  {
    title: "Planner",
    description:
      "App for planning monthly expenses. Track your savings. Take control of your budget.",
    year: 2021,
    links: {
      code: "https://github.com/vansmoe/jjddr2-lambda-warriors",
      preview: "",
    },
    images: [],
  },

  // {
  //   title: "",
  //   description: "",
  //   year: 0,
  //   links: {
  //     code: "",
  //     preview: "",
  //   },
  //   images: []
  // }
]

export const techList = [
  "javascript",
  "sass",
  "react",
  "vue",
  "gatsby",
  "bootstrap",
  "npm",
  "java",
  "jest",
  "formik",
  "typescript",
  "axios",
  "styled-components",
  "tailwind",
  "material-ui",
  "mysql",
  "spring",
  "figma",
]

// text="I really enjoy graphic aspect of my work. For me it's important to make product that customer would love for its interactivity and visual pleasure."
// text="Last half year I spent in java environment and I love it. I've attended to Java bootcamp from InfoShare Academy. Back-end languages gives somany capabilities.  OOP rules."
// text="My programming adventure starts with front-end. I'm freelancing for about 2 years. My main principales are DRY and KISS, also it's important to keep it lightweight and fast."

export default data
