import React from "react"
import styled from "styled-components"
import TechItem from "./TechItem"
import WorkItem from "./WorkItem"
import data, { techList } from "./WorkData"

const _ = require("lodash/core")

const Wrapper = styled.div`
  margin-top: 6rem;
`
const Items = styled.div``

const SectionWrapper = styled.section`
  padding: 5%;
`

const TechsWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  margin: 4rem 0;
`

const H = styled.h3`
  font-size: ${({ theme }) => theme.font.size.l};
  font-weight: 500;
  margin-bottom: 2rem;
`

const Top = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
`

const Line = styled.div`
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.colors.dark};
`

const Year = styled.div`
  margin-right: 2rem;
  font-weight: 100;
`

const years = {}

data
  .sort((a, b) => b.year - a.year)
  .forEach(elem => {
    if (_.has(years, elem.year)) {
      years[elem.year].push(elem)
    } else {
      _.extend(years, { [elem.year]: [elem] })
    }
  })

const Work = () => (
  <SectionWrapper className="container">
    <H>Portfolio</H>
    <TechsWrapper>
      {techList.map(item => (
        <TechItem key={item} name={item} />
      ))}
    </TechsWrapper>
    <Wrapper>
      {_.keys(years)
        .sort()
        .reverse()
        .map(year => (
          <Items key={`year-${year}`}>
            <Top>
              <Year>{year}</Year>
              <Line />
            </Top>

            {years[year].map((item, index) => {
              if (years[year].length > index + 1) {
                return (
                  <>
                    <WorkItem
                      key={`work-${index + 1}`}
                      title={item.title}
                      data={item}
                    />
                    <Line />
                  </>
                )
              }
              return (
                <WorkItem
                  key={`work-${index + 1}`}
                  title={item.title}
                  data={item}
                />
              )
            })}
          </Items>
        ))}
    </Wrapper>
  </SectionWrapper>
)

export default Work
