import React from "react"
import styled from "styled-components"

const StyledWrapper = styled.section`
  min-height: 100vh;
  display: flex;
  flex-flow: column wrap;
  justify-content: flex-end;

  @media all and (max-width: 992px) {
    margin-top: 25vh;
    justify-content: start;
  }
`

const WelcomeText = styled.div`
  margin: 5%;
  max-width: 80vw;
`

const H1 = styled.h1`
  font-size: ${({ theme }) => theme.font.size.xl};
  font-family: ${({ theme }) => theme.font.family.serif};
  line-height: 1.1;
`

const Hero = () => (
  <StyledWrapper className="container">
    <WelcomeText>
      <H1>
        Creative, detail-oriented Front-end Developer interested in product
        design tinkerer, building user-friendly web apps.
      </H1>
    </WelcomeText>
  </StyledWrapper>
)

export default Hero
