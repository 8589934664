import React from "react"
import { Link } from "react-scroll"
import styled from "styled-components"

const Navigation = styled.nav`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-evenly;
`

const NavLink = styled.div`
  display: inline-block;
  margin: 1rem;
  font-size: ${({ theme }) => theme.font.size.r};
  font-weight: 600;
  cursor: pointer;

  &:hover {
    opacity: 50%;
  }

  @media all and (max-width: 768px) {
    margin: 0;
    padding: 1rem;
  }
`

const Nav = () => (
  <Navigation>
    <Link activeClass="active" to="home" smooth spy offset={-80}>
      <NavLink>Home</NavLink>
    </Link>
    <Link activeClass="active" to="aboutMe" smooth spy offset={-80}>
      <NavLink>About</NavLink>
    </Link>
    <Link
      activeClass="active"
      to="portfolio"
      smooth
      spy
      duration={2000}
      offset={-80}
    >
      <NavLink>Portfolio</NavLink>
    </Link>
    <Link
      activeClass="active"
      to="contact"
      smooth
      spy
      duration={2000}
      offset={-80}
    >
      <NavLink>Contact</NavLink>
    </Link>
  </Navigation>
)

export default Nav
